import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { compose, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import localForage from 'localforage';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import rootReducer from './rootReducer';
import { unregister } from './registerServiceWorker';
import './containers/shared/css/global.css';
import App from './containers/App';

require('typeface-roboto');

// Check if polyfill required for Promise
if (!Promise) {
  require('es6-promise/auto'); // eslint-disable-line global-require
}

const renderApp = () => {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <App />
          </Router>
        </PersistGate>
      </Provider>
    </I18nextProvider>,
    document.getElementById('xrpl-explorer')
  );
};

const isDevelopment = process.env.NODE_ENV === 'development';
const persistConfig = {
  key: 'xrpl_explorer',
  storage: localForage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewarePackages = [thunk];
let enhancers;
let middleware = applyMiddleware(...middlewarePackages);
let store;
let persistor;
if (isDevelopment) {
  localStorage.setItem('debug', 'xrpl-debug:*');
  Promise.all([import('redux-logger'), import('redux-devtools-extension')]).then(
    ([reduxLogger, devtools]) => {
      middlewarePackages.push(reduxLogger.logger);
      middleware = applyMiddleware(...middlewarePackages);
      enhancers = devtools.composeWithDevTools(middleware);
      store = createStore(persistedReducer, enhancers);
      persistor = persistStore(store);
      renderApp();
    }
  );
} else {
  localStorage.removeItem('debug');
  enhancers = compose(middleware);
  store = createStore(persistedReducer, enhancers);
  persistor = persistStore(store);

  // Check if polyfill required
  if (1 || !Intl || !Map || !Set) {
    Promise.all([import('intl'), import('raf'), import('core-js')]).then(renderApp);
  } else {
    // Polyfill wasn't needed, carry on
    renderApp();
  }
}

unregister();
