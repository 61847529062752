import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loadable from 'react-loadable';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { analytics, ANALYTIC_TYPES } from '../shared/utils';
import ComponentAsyncLoading from '../shared/components/ComponentAsyncLoading';
import { updateViewportDimensions, onScroll, updateLanguage } from './actions';
import Ledgers from '../Ledgers';
import Header from '../Header';
import Footer from '../Footer';
import Banner from '../Header/Banner'; // included here for spacing
import './app.css';

class App extends Component {
  static componentDidCatch(error, info) {
    analytics(ANALYTIC_TYPES.exception, {
      exDescription: `${error.toString()} -------->>>>>  ${info.componentStack}`
    });
  }

  constructor(props) {
    super(props);
    const { actions, i18n, language } = props;

    if (i18n.language !== language) {
      actions.updateLanguage(i18n.language);
    }

    props.actions.updateViewportDimensions();
  }

  componentDidMount() {
    const { actions } = this.props;
    window.addEventListener('resize', actions.updateViewportDimensions);
    window.addEventListener('scroll', actions.onScroll);
  }

  componentWillUnmount() {
    const { actions } = this.props;
    window.removeEventListener('resize', actions.updateViewportDimensions);
    window.removeEventListener('scroll', actions.onScroll);
  }

  render() {
    const { t, location } = this.props;

    /* START: Map lagacy routes to new routes */
    if (location.hash && location.pathname === '/') {
      if (location.hash.indexOf('#/transactions/') === 0) {
        const identifier = location.hash.split('#/transactions/')[1];
        return <Redirect to={`/transactions/${identifier}`} />;
      }
      if (location.hash.indexOf('#/graph/') === 0) {
        const identifier = location.hash.split('#/graph/')[1];
        return <Redirect to={`/accounts/${identifier}`} />;
      }
    }
    /* END: Map lagacy routes to new routes */

    if (location.pathname === '/explorer') {
      return <Redirect to="/" />;
    }

    if (location.pathname === '/ledgers') {
      return <Redirect to="/" />;
    }

    const asyncLedger = Loadable({
      loader: () => import('../Ledger'),
      loading: ComponentAsyncLoading
    });

    const asyncAccounts = Loadable({
      loader: () => import('../Accounts'),
      loading: ComponentAsyncLoading
    });

    const asyncTransactions = Loadable({
      loader: () => import('../Transactions'),
      loading: ComponentAsyncLoading
    });

    const asyncNetwork = Loadable({
      loader: () => import('../Network'),
      loading: ComponentAsyncLoading
    });

    const asyncValidators = Loadable({
      loader: () => import('../Validators'),
      loading: ComponentAsyncLoading
    });

    const asyncPayIds = Loadable({
      loader: () => import('../PayIds'),
      loading: ComponentAsyncLoading
    });

    // 404 component
    const asyncNoMatch = Loadable({
      loader: () => import('../NoMatch'),
      loading: ComponentAsyncLoading
    });

    return (
      <div className="app">
        <Helmet>
          <meta name="description" content={t('app.meta.description')} />
          <meta name="author" content={t('app.meta.author')} />
        </Helmet>
        <Banner />
        <Header />
        <div className="content">
          <Switch>
            <Route exact path="/" component={Ledgers} />
            <Route exact path="/ledgers/:identifier" component={asyncLedger} />
            <Route exact path="/accounts/:id?" component={asyncAccounts} />
            <Route exact path="/transactions/:identifier?" component={asyncTransactions} />
            <Route exact path="/transactions/:identifier/:tab" component={asyncTransactions} />
            <Route exact path="/network/:tab?" component={asyncNetwork} />
            <Route exact path="/validators/:identifier?" component={asyncValidators} />
            <Route exact path="/payids/:id?" component={asyncPayIds} />
            <Route component={asyncNoMatch} />
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}

App.propTypes = {
  t: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
    changeLanguage: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired,
  actions: PropTypes.shape({
    updateViewportDimensions: PropTypes.func,
    onScroll: PropTypes.func,
    updateLanguage: PropTypes.func
  }).isRequired
};

export default withRouter(
  translate()(
    connect(
      state => ({
        language: state.app.language
      }),
      dispatch => ({
        actions: bindActionCreators(
          {
            updateViewportDimensions,
            onScroll,
            updateLanguage
          },
          dispatch
        )
      })
    )(App)
  )
);
