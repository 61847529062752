import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Loader from './Loader';

const ComponentAsyncLoading = props => {
  const { t } = props;
  if (props.isLoading) {
    // While our other component is loading...
    if (props.timedOut) {
      // In case we've timed out loading our other component.
      return <div className="async-component-error">{t('async_component_timedout')}</div>;
    } else if (props.pastDelay) {
      // Display a loading screen after a set delay.
      // TODO: mybe snack bar is better won't jump the screen
      return <Loader />;
    }
    // Don't flash "Loading..." when we don't need to.
    return null;
  } else if (props.error) {
    // If we aren't loading, maybe
    return <div className="async-component-error">{t('async_component_failed')}</div>;
  }
  // This case shouldn't happen... but we'll return null anyways.
  return null;
};

ComponentAsyncLoading.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  pastDelay: PropTypes.bool,
  timedOut: PropTypes.bool,
  error: PropTypes.bool
};

ComponentAsyncLoading.defaultProps = {
  isLoading: false,
  pastDelay: false,
  timedOut: false,
  error: false
};

export default translate()(ComponentAsyncLoading);
