import { combineReducers } from 'redux';
import appReducer, { initialState as appState } from './containers/App/reducer';
import ledgerReducer, { initialState as ledgerState } from './containers/Ledger/reducer';
import accountHeaderReducer, {
  initialState as accountHeaderState
} from './containers/Accounts/AccountHeader/reducer';
import accountTransactionsReducer, {
  initialState as accountTransactionsState
} from './containers/Accounts/AccountTransactionsTable/reducer';
import transactionReducer, {
  initialState as transactionState
} from './containers/Transactions/reducer';
import validatorReducer, { initialState as validatorState } from './containers/Validators/reducer';
import payIdReducer, {
  initialState as payIdState
} from './containers/PayIds/PayIdMappingsTable/reducer';

export const initialState = {
  app: appState,
  accountHeader: accountHeaderState,
  accountTransactions: accountTransactionsState,
  ledger: ledgerState,
  transaction: transactionState,
  validator: validatorState,
  payIdData: payIdState
};

const rootReducer = combineReducers({
  app: appReducer,
  accountHeader: accountHeaderReducer,
  accountTransactions: accountTransactionsReducer,
  ledger: ledgerReducer,
  transaction: transactionReducer,
  validator: validatorReducer,
  payIdData: payIdReducer
});

export default rootReducer;
