// NOTE: for submenus, remove `path` field and add `children` array of objects
const routesConfig = [
  {
    path: '/',
    title: 'explorer'
  },
  {
    path: '/network',
    title: 'network'
  }
];

export default routesConfig;
